define("additive-content/routes/instance", ["exports", "additive-content/config/environment", "@ember/routing/route", "@ember/service", "@ember/object", "ember-concurrency", "@additive-apps/auth/mixins/auth-route-mixin", "@additive-apps/ui/constants", "@additive-apps/ui/utils/app-utils", "@additive-apps/utils/utils/plans", "@userback/widget"], function (_exports, _environment, _route, _service, _object, _emberConcurrency, _authRouteMixin, _constants, _appUtils, _plans, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let InstanceRoute = _exports.default = (_class = class InstanceRoute extends _route.default.extend(_authRouteMixin.default) {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "authenticatedFetch", _descriptor, this);
      _initializerDefineProperty(this, "currentUser", _descriptor2, this);
      _initializerDefineProperty(this, "session", _descriptor3, this);
      _initializerDefineProperty(this, "store", _descriptor4, this);
      _initializerDefineProperty(this, "uiAppSettings", _descriptor5, this);
      _initializerDefineProperty(this, "uiLocale", _descriptor6, this);
    }
    async model(params) {
      var _this$currentUser$cur, _this$currentUser$cur2, _this$currentUser, _this$currentUser2;
      const organization = await this.store.findRecord('organization', params.instance_id);
      this.currentUser.set('currentOrganization', organization);
      await this.currentUser.loadUser();
      await this.uiAppSettings.instanceTask.perform();
      let tasks = [];
      !_appUtils.fetchAccessibleApps.task.isRunning && tasks.push(_appUtils.fetchAccessibleApps.task.perform(this, organization.id));
      await Promise.all(tasks);
      await (0, _emberConcurrency.waitForProperty)(_appUtils.fetchAccessibleApps.task, 'isRunning', false);
      const accessibleAppsIds = (_this$currentUser$cur = this.currentUser.currentOrganization.accessibleApps) === null || _this$currentUser$cur === void 0 ? void 0 : _this$currentUser$cur.map(app => app.id);
      const crmApp = (_this$currentUser$cur2 = this.currentUser.currentOrganization.accessibleApps) === null || _this$currentUser$cur2 === void 0 ? void 0 : _this$currentUser$cur2.find(app => app.id === _constants.APP_ID_CRM);
      if (accessibleAppsIds !== null && accessibleAppsIds !== void 0 && accessibleAppsIds.length) {
        var _this$uiAppSettings, _this$uiAppSettings2, _crmApp$planName;
        const currentOrganization = this.currentUser.currentOrganization;
        const hasLandingPageModule = !!((_this$uiAppSettings = this.uiAppSettings) !== null && _this$uiAppSettings !== void 0 && (_this$uiAppSettings = _this$uiAppSettings.modules) !== null && _this$uiAppSettings !== void 0 && _this$uiAppSettings.find(element => element === 'landing-page'));
        const hasJournalModule = !!((_this$uiAppSettings2 = this.uiAppSettings) !== null && _this$uiAppSettings2 !== void 0 && (_this$uiAppSettings2 = _this$uiAppSettings2.modules) !== null && _this$uiAppSettings2 !== void 0 && _this$uiAppSettings2.find(element => element === 'journal'));
        const crmAppPlanName = crmApp === null || crmApp === void 0 || (_crmApp$planName = crmApp.planName) === null || _crmApp$planName === void 0 ? void 0 : _crmApp$planName.slice(0, crmApp === null || crmApp === void 0 ? void 0 : crmApp.planName.indexOf('_'));
        currentOrganization.set('hasLandingPage', accessibleAppsIds.includes(_constants.APP_ID_VOUCHER) || crmApp && (0, _plans.gte)(crmAppPlanName, _plans.PLAN_PROFESSIONAL) || accessibleAppsIds.includes(_constants.APP_ID_NEWSLETTER) || accessibleAppsIds.includes(_constants.APP_ID_MARKETING_CLOUD) || hasLandingPageModule || hasJournalModule);
        this.currentUser.set('currentOrganization', currentOrganization);
      }
      if (this.currentUser.isAdditiveUser || this.currentUser.isPartnerUser) {
        (0, _widget.default)(this.currentUser.isAdditiveUser ? _environment.default.APP.userbackAccessToken : _environment.default.APP.userbackAccessTokenPartner, {
          email: this.currentUser.user.email,
          name: this.currentUser.user.fullName,
          categories: 'Inhalte'
        });
      }
      await this.uiLocale.setLocale(((_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 || (_this$currentUser = _this$currentUser.user) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.language) || ((_this$currentUser2 = this.currentUser) === null || _this$currentUser2 === void 0 || (_this$currentUser2 = _this$currentUser2.currentOrganization) === null || _this$currentUser2 === void 0 ? void 0 : _this$currentUser2.language) || 'de');
      const managedByPartner = this.uiAppSettings.managedByPartner && !(this.currentUser.user.isPartner || this.currentUser.user.isAdditive);
      const managedByAdditive = this.uiAppSettings.managedByAdditive && !this.currentUser.user.isAdditive;
      this.currentUser.set('hasReadOnlyContents', this.currentUser.isViewer);
      this.currentUser.set('hasReadOnlyLPsAndWidgets', managedByPartner || managedByAdditive || this.currentUser.isViewer);
      this.currentUser.set('hasReadOnlySettings', managedByPartner || managedByAdditive || !this.currentUser.isManager);
      return organization;
    }
    loading() {
      return !this.currentUser.currentOrganization;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "authenticatedFetch", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "uiAppSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "uiLocale", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "loading", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loading"), _class.prototype), _class);
});